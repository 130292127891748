<template>
    <div>
        <ul>
            <li>消息编号: {{$route.query.id}}</li>
            <li>消息标题: {{$route.query.title}}</li>

            <li>消息编号: {{$route.params.id}}</li>
            <li>消息标题: {{$route.params.title}}</li>

            <li>id: {{id}}</li>
            <li>title: {{title}}</li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'Detail',
        mounted(){
            console.log(this.$route)
        },
        props:['id', 'title']
    }
</script>

<style  scoped>

</style>