var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',_vm._l((_vm.messageList),function(message){return _c('li',{key:message.id},[_c('router-link',{attrs:{"to":{
        path: '/home/message/detail1',
        query: {
          id: message.id,
          title: message.title
        }
      }}},[_vm._v(" "+_vm._s(message.title)+" ")]),_vm._v("   "),_c('router-link',{attrs:{"to":{
        // 传递 params 参数时不可以使用 path，必须使用 name
        name: 'detail',
        // path: '/home/message/detail',
        params: {
          id: message.id,
          title: message.title
        }
      }}},[_vm._v(" "+_vm._s(message.title)+" ")]),_vm._v("   "),_c('button',{on:{"click":function($event){return _vm.pushShow(message)}}},[_vm._v("push 查看")]),_c('button',{on:{"click":function($event){return _vm.replaceShow(message)}}},[_vm._v("replace 查看")])],1)}),0),_c('hr'),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }