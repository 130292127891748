<template>
    <div>
        <h1>我是 About 的内容</h1>
    </div>
</template>

<script>
    export default {
        name: 'About',

        // 当组件切换的时候，旧的路由组件被销毁，新的路由组件被挂载
        beforeDestroy(){
            console.log('About 组件即将被销毁了')
        },
        mounted(){
            console.log('About 组件挂在完毕了')
        },

        // 组件内路由守卫 通过路由规则，进入该组件时被调用
        beforeRouteEnter(to, from , next){
            console.log('进入')
            next()
        },

        // 通过路由组件，离开该组件时被调用
        beforeRouteLeave(to, from, next){

            console.log('离开')
            next()
        }
    }
</script>

<style scoped>

</style>