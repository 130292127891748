<template>
    <div>

        <div class="col-xs-offset-2 col-xs-8">
                <div class="page-header"><h2>Vue Router Demo</h2>
                    <button @click="back">后退</button>
                    <button @click="forward">前进</button>
                </div>
                </div>
    </div>
</template>

<script>
    export default {
        name: 'Banner',
        methods:{
            back(){
                this.$router.back()

            },
            forward(){
                this.$router.forward()
            },

            go(){
                // 正数代表前进 n 步，负数代表后退 n 步
                this.$router.go(3)
            }
        }
    }
</script>

<style scoped>

</style>