<template>
    <div>
      <ul>
        <li v-for="message in messageList" :key="message.id">
          <!-- 跳转路由并携带 query 参数， to的字符串写法 -->
          <!-- <router-link :to="`/home/message/detail?id=${message.id}&title=${message.title}`">{{message.title}}</router-link>&nbsp;&nbsp; -->

          <!-- 跳转路由并携带 params 参数， to的字符串写法 -->
          <!-- <router-link :to="`/home/message/detail/${message.id}/${message.title}`">{{message.title}}</router-link>&nbsp;&nbsp;  -->
            <!-- 跳转路由并携带 query 参数， to的对象写法 -->
          <router-link :to="{
            path: '/home/message/detail1',
            query: {
              id: message.id,
              title: message.title
            }
          }">
          {{message.title}}
          </router-link>&nbsp;&nbsp;

          <router-link :to="{
            // 传递 params 参数时不可以使用 path，必须使用 name
            name: 'detail',
            // path: '/home/message/detail',
            params: {
              id: message.id,
              title: message.title
            }
          }">
          {{message.title}}
          </router-link>&nbsp;&nbsp;
          <button @click="pushShow(message)">push 查看</button>
          <button @click="replaceShow(message)">replace 查看</button>
        </li>
      </ul>
      <hr>
      <router-view></router-view>
    </div>
  </template>
  
  <script>

  export default {
    name: "Message",
    data(){
      return {
        messageList: [
          {id: '001', title: '消息001'},
          {id: '002', title: '消息002'},
          {id: '003', title: '消息003'},

        ]
      }
    },
    methods:{
      pushShow(message){
        this.$router.push({
            path: '/home/message/detail1',
            query: {
              id: message.id,
              title: message.title
            }
        }).catch(()=>{})
      },
      replaceShow(message){
        this.$router.replace({
            path: '/home/message/detail1',
            query: {
              id: message.id,
              title: message.title
            }
        }).catch(()=>{})
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>