<template>
    <div>
        <h2>Home组件内容</h2>
        <div>
      <ul class="nav nav-tabs">
        <li>
          <router-link class="list-group-item" active-class="active" to="/home/news">News</router-link>
        </li>
        <li>
          <router-link class="list-group-item" active-class="active" to="/home/message">Message</router-link>
        </li>
      </ul>
      <!-- keep-alive 的作用是缓存路由组件，当组件切走时不销毁，includes 表示缓存的时那个组件，组件名, 多个的话 写成 :include="['News', 'Message']" -->
      <keep-alive include="News">
        <router-view>
        </router-view>
      </keep-alive>
     
    </div>
    </div>
</template>

<script>
    export default {
        name: 'Home',
        beforeDestroy(){
            console.log('Home 组件即将被销毁了')
        },
        mounted(){
            console.log('Home 组件挂在完毕了')
        }
    }
</script>

<style  scoped>

</style>