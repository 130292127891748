// 该文件专门用于创建整个应用的路由器
import Vue from 'vue'
import VueRouter from 'vue-router'
import About from '../pages/About.vue'

import Home from '../pages/Home'
import Message from '../pages/Message.vue'
import News from '../pages/News.vue'
import Detail from '../pages/Detail.vue'
// 使用路由插件
Vue.use(VueRouter)
// 创建并导出路由器
const router =  new VueRouter({

    // 设置路由的工作模式，hash 和 history 两种，hash 表示在路由的路径前面加一个 # ，# 后面的东西不会发给服务器，history则没有
    mode:  'history',

    routes:[
        {
            name: 'about',
            path: '/about',
            component: About,

            // 路由元数据，可以自定义一些信息作为业务逻辑用
            meta: {isAuth: 'false'},
            // 独享路由守卫
            beforeEnter: (to, from, next) => {
                if(to.meta.isAuth){
                    if(localStorage.getItem('school')==='qinghua'){
                        next()
                    }else{
                        alert('无权限')
                    }
                }
            }
        },
        {
            name: 'home',
            path: '/home',
            component: Home,
            children:[
                {
                    name: 'news',
                    path: 'news',  // 子路由不需要加 / 
                    component: News
                },
                {
                    name: 'message',
                    path: 'message',  // 子路由不需要加 / 
                    component: Message,
                    children: [{
                        name: 'detail',
                        path: 'detail/:id/:title',
                        component: Detail,
                        //  props 的第一种写法，值为对象,该对象中的所有 key-value 都会以 props 的形式传给 Detail 组件
                        // props: {a: 1, b: 'hello'}
                        
                        // props 的第二种写法，值为 bool， 若 bool为真，就会把该路由组件收到的所有 params 参数，以 props 的形式传递给 Detail 组件
                        // props: true

                        // props 的第三种写法， 值为函数，
                        props($route){
                            return {
                                id: $route.params.id,  // 同理 当传递 query 参数时，可以通过 $route.query.id 获取到数据
                                title: $route.params.title
                            }
                        }
                    },
                    {
                        path: 'detail1',
                        component: Detail,
                        
                    }
                        
                    ]
                }
            ]
        }
    ]
})

// 全局前置路由守卫   初始化及每一次路由切换之前调用
router.beforeEach((to, from, next) => {
    // 读出路由元数据
    console.log(to.meta.isAuth)
    if(to.path === '/home/news' || to.path==='/home/message'){
        if(localStorage.getItem('school')==='qinghua'){
            next()
        }else{
            alert('无权限')
        }
    }else{
        next()
    }
})


// 后置路由守卫   初始化及每一次路由切换之后调用
router.afterEach((to, from)=>{
    console.log('后置路由守卫', )
})
export default router