<template>
    <ul>
      <li :style="{opacity}">欢迎学习Vue</li>
      <li>news001 <input type="text"></li>  
      <li>news002 <input type="text"></li>
      <li>news003 <input type="text"></li> 
    </ul>
  </template>
  
  <script>
  export default {
    name: "News",
    data(){
      return {
        opacity: 1
      }
     
    },
    mounted(){
      console.log('mounted', this)
      
    },
    beforeDestroy(){
      console.log('New 组件即将被销毁了')
      
    },

    // 两个新的生命周期钩子（路由组件独有的）
    // 组件激活
    activated(){
      this.timer = setInterval(() => {
        console.log('@')
        this.opacity -= 0.01
        if(this.opacity <= 0) this.opacity = 1
      }, 16)
      console.log('News 组件被激活了')
    },
    // 组件失活
    deactivated(){
      console.log('News 组件失活了')
      clearInterval(this.timer)
    }
  }
  </script>
  
  <style scoped>
  
  </style>